
//本地引入
import i18n from "@/lang";
import router from "@/router";
import settings from "@/settings";
import { getPwdErrMsg } from "@/utils";
import { CfgAnalyze } from "@/utils/cfg-analyze";
import { UserModule } from "@/store/modules/user";
import { isValidPassword } from "@/utils/validate";

//公共库
import { Route } from "vue-router";
import { Form, Input } from "element-ui";
import { Dictionary } from "vue-router/types/router";
import { PermissionModule } from "@/store/modules/permission";
import { Component, Vue, Watch } from "vue-property-decorator";

//组件
@Component({
  name: "Login",
})

//组件函数
export default class extends Vue {
  //登录参数
  private loginForm = {
    code: "",
    username: "",
    password: "",
  };

  //定义变量
  private loading: boolean = false; //加载
  private capsTooltip: boolean = false; //大小写提示
  private passwordType: string = "password"; //密码类型

  //获取标题
  private getTitle(): string {
    return `${settings.title}`;
  }

  //处理登录
  public handleLogin(): void {
    //验证表单数据
    (this.$refs.loginForm as Form).validate(async (valid) => {
      //成功
      if (valid) {
        //显示加载
        this.loading = true;

        //超时隐藏记载
        setTimeout(() => {
          this.loading = false;
        }, 5 * 1000);

        //账号登录
        await UserModule.authLogin(this.loginForm);

        //路由处理
        this.$router
          .push({
            path: this.redirect || "/",
            query: this.otherQuery,
          })
          .catch((err) => {
            console.warn(err);
          });

        //隐藏等待界面
        setTimeout(() => {
          this.loading = false;
        }, 0.5 * 1000);
      }
    });
  }

  //登录成功
  private loginSuccess(): void {
    //隐藏等待
    this.loading = false;

    //创建路由
    PermissionModule.generateRoutes();

    //获取动态路由
    PermissionModule.dynamicRoutes.forEach((route: any) => {
      router.addRoute(route);
    });

    //路由处理
    this.$router
      .push({
        path: this.redirect && this.redirect != "/" ? this.redirect : "/dashboard",
        query: this.otherQuery,
      })
      .catch((err) => {
        console.warn(err);
      });
  }

  //显示密码
  private showPassword(): void {
    //设置普通文本
    if (this.passwordType === "password") {
      this.passwordType = "";
    }
    //设置密码文本
    else {
      this.passwordType = "password";
    }

    //获取焦点
    this.$nextTick(() => {
      (this.$refs.password as Input).focus();
    });
  }

  //获取版本
  private getVersionCode(): string {
    //返回版本
    return `v${CfgAnalyze.getPackType()}.${CfgAnalyze.getVersionCode()}`;
  }

  //验证用户名
  private validateUsername = (rule: any, value: string, callback: Function) => {
    //用户名不能为空
    if (value == "") {
      //提示
      callback(new Error(i18n.t("utils.usernameErrorTips") as any));
    }
    //成功
    else {
      callback();
    }
  };

  //验证密码
  private validatePassword = (rule: any, value: string, callback: Function) => {
    //验证
    const vType = isValidPassword(value);

    //失败
    if (vType !== 1) {
      const msg = getPwdErrMsg(vType);
      callback(new Error(msg as any));
    }
    //成功
    else {
      callback();
    }
  };

  //登录规则
  private loginRules = {
    username: [{ validator: this.validateUsername, trigger: "blur" }],
    password: [{ validator: this.validatePassword, trigger: "blur" }],
  };

  //----------------------------- 路由处理 -----------------------------
  //定义变量
  private redirect?: string;
  private otherQuery: Dictionary<string> = {};

  //路由改变
  @Watch("$route", { immediate: true })
  private onRouteChange(route: Route): void {
    const query = route.query as Dictionary<string>;
    if (query) {
      this.redirect = query.redirect;
      this.otherQuery = this.getOtherQuery(query);
    }
  }

  //获取其他路由
  private getOtherQuery(query: Dictionary<string>) {
    return Object.keys(query).reduce((acc, cur) => {
      if (cur !== "redirect") {
        acc[cur] = query[cur];
      }
      return acc;
    }, {} as Dictionary<string>);
  }
}
